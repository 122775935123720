import { Grid, List, Paper, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import Tree from '../../components/Tree';
import TreeBreadcrumbs from '../../components/TreeBreadcrumbs';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { useGlobalStyles } from '../../utils/styles';
import { planeacionFuente, planeacionUpdatedAt, stats } from '../../utils/transparencia-difusion/constants';
import { IListDocs } from '../../utils/transparencia-difusion/interfaces';
import { useStyles } from '../../utils/transparencia-difusion/styles';


const Planeacion = () => {
  const theme = useTheme();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [data, setData] = useState<IListDocs[]>(stats);
  const [breadcrumbs, setBreadcrumbs] = useState<String[]>([]);
  const getTreeBreadcrumbs = (data: String[]) => {
    setBreadcrumbs(data);
  }

  return (
    <Layout>
      <SEO title='Planeacion' />

      <div className={globalClasses.container}>
      <Header title='Información estadística' />

        <div className={globalClasses.content}>
        <Typography>Aquí puedes cosultar los informes estadisticos anuales y de población derechohabiente mensuales del Instituto de Seguridad Social del Estado de Tabasco:</Typography>
          <Grid item xs={12}>
            {
                    data?.length ? (
                        <Paper style={{ marginTop: theme.spacing(2) }}>
                            <List disablePadding>
                            {   <>
                                      <TreeBreadcrumbs 
                                        array={breadcrumbs}
                                        setTreeBreadcrumbs={getTreeBreadcrumbs}
                                        //initialArray={"Inicio"}
                                      />
                                      <Tree 
                                          initialArray={data}
                                          activeBreadcrumbs={true}
                                          breadcrumbs={breadcrumbs}
                                          setBreadcrumbs={setBreadcrumbs}
                                      />
                                  </>
                              }
                            </List>
                        </Paper>
                    ): null
                  }


                </Grid>

                <UpdatedInfo fuente={planeacionFuente} updatedAt={planeacionUpdatedAt} />
        </div>
      </div>
    </Layout>
  );

  
};

export default Planeacion;